<template>
  <div>
    <el-card>
      <el-form
        ref="form"
        :model="form"
        :rules="addFormRulue"
        label-width="130px"
      >
        <el-form-item label="查看联系方式次数" prop="viewContact">
          <el-input-number
            v-model="form.viewContact"
            label="请输入数字"
            :min="-1"
          />
          <div class="box">-1为不限次数</div>
        </el-form-item>
        <el-form-item label="查看报告次数" prop="viewReport">
          <el-input-number
            v-model="form.viewReport"
            label="请输入数字"
            :min="-1"
          />
          <div class="box">-1为不限次数</div>
        </el-form-item>
        <el-form-item label="合作申请次数" prop="cooperationApply">
          <el-input-number
            v-model="form.cooperationApply"
            label="请输入数字"
            :min="-1"
          />
          <div class="box">-1为不限次数</div>
        </el-form-item>
        <el-form-item label="合作置顶次数" prop="cooperationTop">
          <el-input-number
            v-model="form.cooperationTop"
            label="请输入数字"
            :min="-1"
          />
          <div class="box">-1为不限次数</div>
        </el-form-item>
        <el-form-item label="合作刷新次数" prop="cooperationRefresh">
          <el-input-number
            v-model="form.cooperationRefresh"
            label="请输入数字"
            :min="-1"
          />
          <div class="box">-1为不限次数</div>
        </el-form-item>
        <el-form-item label="合作排序优先级" prop="cooperationSort">
          <el-input-number
            v-model="form.cooperationSort"
            label="请输入数字"
            :min="-1"
          />
          <div class="box">-1为不限次数</div>
        </el-form-item>
        <el-form-item label="发送名片次数" prop="sendCard">
          <el-input-number
            v-model="form.sendCard"
            label="请输入数字"
            :min="-1"
          />
          <div class="box">-1为不限次数</div>
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input-number v-model="form.price" label="请输入数字" :min="0" />
        </el-form-item>
        <el-form-item label="有效时长" prop="month">
          <el-input-number v-model="form.month" label="请输入数字" :min="0" />
        </el-form-item>
        <!-- <el-form-item label="来源" prop="source">
          <el-input
            v-model="form.source"
            :maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="地区" prop="areaCode">
          <el-select v-model="form.areaCode" placeholder="请选择">
            <el-option
              v-for="(item, index) in area.reportArea"
              :key="index"
              :label="item"
              :value="index * 1"
              :data="action"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年份" prop="year">
          <el-select v-model="form.year" placeholder="请选择">
            <el-option
              v-for="(item, index) in area.reportYear"
              :key="index"
              :label="item"
              :value="index * 1"
              :data="action"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查看方式" prop="viewType">
          <el-select v-model="form.viewType" placeholder="请选择">
            <el-option label="在线查看" :value="1"> </el-option>
            <el-option label="下载" :value="2"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="行业" prop="businessId">
          <el-select v-model="form.businessId" placeholder="请选择">
            <el-option
              v-for="(item, index) in area.reportBusiness"
              :key="index"
              :label="item"
              :value="index * 1"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="置顶">
          <el-select v-model="form.top" placeholder="请选择">
            <el-option label="是" :value="1" />
            <el-option label="否" :value="0" />
          </el-select>
        </el-form-item> -->
        <el-form-item label="VIP图标" prop="logo">
          <!-- <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            accept=".jpg,.jpeg,.png,.gif,.ico"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess_1"
            :data="action_1"
          >
            <img
              v-if="form.banner"
              :src="baseurl + yu + form.banner"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div class="el-upload__tip waring" slot="tip">
              请上传比例16:9的图片，否则会影响前台显示 <br />
              大小不可超过1M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload> -->
          <up-img
            :action="action_1"
            :banner="form.logo"
            :tip="false"
            @event="setImg"
          />
        </el-form-item>
        <!-- <el-form-item label="PDF">
          <el-upload
            ref="pdf"
            class="upload-demo"
            :on-progress="beforeAvatarUpload"
            :on-remove="word"
            :action="this.$store.getters.imgUrl"
            accept=".pdf"
            :headers="header"
            :on-success="handleAvatarSuccess"
            :data="action"
            :on-preview="load"
            :limit="1"
            :show-file-list="true"
            :file-list="sd"
          >
            <el-button size="small" type="primary" v-if="pdfstaus"
              >点击上传</el-button
            >
            <button
              size="small"
              type="primary"
              :disabled="true"
              v-else
              class="lod"
            >
              加载中
            </button>
            <div class="el-upload__tip waring" slot="tip">
              大小不可超过60M,只允许传pdf文件
            </div>
          </el-upload>
        </el-form-item> -->
        <!-- <el-form-item label="内容" :show-overflow-tooltip="true" prop="content">
          <Tiny :value="form.content" @input="chang"></Tiny>
        </el-form-item> -->
        <!-- <el-form-item label="初始阅读量">
          <el-input-number
            v-model="form.initViews"
            placeholder="请输入内容"
            :min="0"
          /> -->
        <!-- <el-tooltip class="item" effect="dark" content="初始阅读量默认值∶50-200随机。 每篇文章虚拟阅读量发布48小时内,每小时随机增加10-50阅读量。" placement="top">
            <i class="el-icon-question"></i>
          </el-tooltip> -->
        <!-- </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="editadd">{{
            btn ? '修 改' : 'loading..'
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
// import Tiny from '../../components/Tinymce'
import upImg from '../../components/upImg'

export default {
  components: {
    // Tiny,
    upImg
  },
  data() {
    return {
      sd: [],
      form: {
        viewContact: '',
        viewReport: '',
        cooperationApply: '',
        cooperationTop: '',
        cooperationRefresh: '',
        cooperationSort: '',
        sendCard: '',
        price: '',
        month: '',
        logo: ''
        // viewsRule: '10,100',
        // initViews: Math.floor(Math.random() * (200 - 50 + 1)) + 50
      },
      pdfstaus: true,
      addFormRulue: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        desc: [{ required: true, message: '请输入描述', trigger: 'blur' }],
        source: [{ required: true, message: '请输入来源', trigger: 'blur' }],
        areaCode: [{ required: true, message: '请输入地区', trigger: 'blur' }],
        source: [{ required: true, message: '请输入来源', trigger: 'blur' }],
        content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        viewType: [
          { required: true, message: '请输入查看方式', trigger: 'blur' }
        ],
        businessId: [
          { required: true, message: '请输入行业', trigger: 'blur' }
        ],
        banner: [{ required: true, message: '请输入配图', trigger: 'blur' }],
        content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        year: [{ required: true, message: '请输入年份', trigger: 'blur' }]
      },
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      btn: true,
      action: {
        action: 'report',
        type: 'pdf'
      },
      action_1: {
        action: 'user'
      },
      area: [],
      header: {
        Token: sessionStorage.getItem('token')
      },
      id: null
    }
  },

  created() {
    // this.getea()
    this.getfrom()
    this.bus.$on('new', () => {
      this.form = {
        title: '',
        source: '',
        picture: '',
        content: '',
        desc: '',
        areaCode: '',
        banner: '',
        viewType: '',
        businessId: '',
        pdfUrl: '',
        viewsRule: '10,100',
        initViews: Math.floor(Math.random() * (200 - 50 + 1)) + 50
      }
    })
  },
  mounted() {
    // console.log(this.area);
    sessionStorage.setItem('action', 'reportEditor')
  },
  methods: {
    setImg(val) {
      this.form.logo = val
    },
    chang(val) {
      this.form.content = val
      console.log(val)
    },
    word() {
      this.pdfstaus = true
    },
    beforeAvatarUpload() {
      this.pdfstaus = false
    },
    getfrom() {
      // console.log(this.$route.params.id)
      if (this.$route.params.id) {
        var id = this.$route.params.id
        this.id = id
        this.$http
          .get('/admin/UserGrade/getById?id=' + id)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              // console.log(res);
              this.form = res.data
              // console.log(this.form)
              this.form.id = id * 1
              //  this.form.initViews =
              //   Math.floor(Math.random() * (200 - 50 + 1)) + 50
            }
          })
      } else {
        this.$router.push('/user_grade_list')
      }
    },

    load() {
      // console.log(file);
      location.href = this.baseurl + this.yu + this.form.pdfUrl
    },
    add() {
      this.$refs.form.validate(() => {
        if (this.btn) {
          this.$http
            .post('/admin/Report/add', this.form)
            .then(({ data: res }) => {
              if (res.errorCode == 200) {
                this.$message.success('操作成功')
                this.$router.push('/report_list')
              }
              this.btn = true
              // if(res.)
            })
        }
        this.btn = false
      })
    },
    editadd() {
      this.$refs.form.validate(() => {
        if (this.btn) {
          this.$http
            .post('/admin/UserGrade/edit', this.form)
            .then(({ data: res }) => {
              if (res.errorCode == 200) {
                this.$message.success(res.message)
                this.$router.push('/user_grade_list')
              }
              this.btn = true
            })
        }
        this.btn = false
      })
    },
    handleAvatarSuccess_1(res) {
      if (res.errorCode == 200) {
        this.form.banner = res.data.url
      }
    },
    handleAvatarSuccess(res, file) {
      this.form.pdfUrl = ''
      if (res.errorCode == 200) {
        this.form.pdfUrl = res.data.url
        this.$forceUpdate()
        // this.form=[...this.form]
      } else {
        
        this.$refs.pdf.clearFiles()
      }
      this.pdfstaus = true
      console.log(file)
      //   console.log(imgurlbase)
    },
    beforeUpload() {
      if (this.pdfstaus) {
        return false
      }
    },
    getea() {
      this.$http.get('/admin/ReportCategory/getList').then(({ data: res }) => {
        if (res.errorCode == 200) {
          this.area = res.data
          console.log(this.area)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  height: 178px;
  display: block;
}
.waring {
  color: #fda400;
  line-height: 24px;
}
.ml {
  margin-left: 10px;
}
.lod {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: not-allowed;
  background-color: #fda400;
  // background: #fff;
  border: 1px solid #dcdfe6;
  color: white;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  padding: 8px 15px;
  font-size: 13px;
  border-radius: 4px;
}
</style>
<style>
.el-message.el-message--error {
  z-index: 20000 !important;
}
.el-select-dropdown.el-popper {
  z-index: 20000 !important;
}
.box {
  display: inline-block;
  margin: 10px;
  color: #fda400;
}
</style>
